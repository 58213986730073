import { useEffect } from 'react';

type Scripts =
    | 'login-script'
    | 'intervention-script'
    | 'maps-script'
    | 'powerbi-script'
    | 'sharepoint-script'
    | 'notifications-script'
    | 'filter-script'
    | 'carto-script'
    | 'reporting-script'
    | 'notifications-preferences-widget'
    | 'administration-widget'
    | 'requests-widget'
    | 'profile-widget'
    | 'obs-eau-widget'
    | 'blog-widget'
    | 'widget-client-identity'
    | 'documents-widget'
    | 'widget-delivery-info'
    | 'contract-widget'
    | 'invoice-history'
    | 'consumption-graph'
    | 'widget-exchanges-switch';

function getURl(scriptType: Scripts) {
    
    let appEnv = process.env.REACT_APP_ENV || ""
    appEnv = appEnv.trim().toLowerCase()

    switch (scriptType) {
        case 'login-script':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/login/login-widget.js`;
        case 'intervention-script':
        return `${process.env.REACT_APP_URL_TO_WIDGETS}/intervention/intervention-widget.js`;
        case 'filter-script':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/filter/filter-widget.js`;
        case 'powerbi-script':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/powerbi/powerbi-widget.js`;
        case 'maps-script':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/maps/maps-widget.js`;
        case 'sharepoint-script':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/sharepoint/sharepoint-widget.js`;
        case 'notifications-script':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/notifications/notifications-widget.js`;
        case 'carto-script':
        return `${process.env.REACT_APP_URL_TO_WIDGETS}/carto/carto-widget.js`;
        case 'reporting-script':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/reportings/reporting-widget.js`;
        case 'notifications-preferences-widget':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/notifications-preferences/notifications-preferences-widget.js`;
        case 'profile-widget':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/profile/profile-widget.js`;
        case 'administration-widget':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/administration/administration-widget.js`;
        case 'requests-widget':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/requests/requests-widget.js`;
        case 'obs-eau-widget':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/obs-eau/obs-eau-widget.js`;
        case 'blog-widget':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/blog/blog-widget.js`;
        case 'widget-client-identity':
            return `https://sr-b2c-${appEnv}-endpoint-01.azureedge.net/widget/client-identity/widget-client-identity.js`;
        case 'documents-widget':
            return `${process.env.REACT_APP_URL_TO_WIDGETS}/documents/documents-widget.js`;
        case 'contract-widget':
            //MOCKED URL
            return `https://sr-b2c-${appEnv}-endpoint-01.azureedge.net/widget/contract/widget-contract.js`;
        case 'widget-delivery-info':
            //MOCKED URL
            return `https://sr-b2c-${appEnv}-endpoint-01.azureedge.net/widget/deliverypoint/info/widget-delivery-info.js`;
        case 'invoice-history':
            //MOCKED URL
            return `https://sr-b2c-${appEnv}-endpoint-01.azureedge.net/widget/invoice/billing-widget.js`;
        case 'consumption-graph':
            //MOCKED URL
            return `https://sr-b2c-${appEnv}-endpoint-01.azureedge.net/widget/consumption/details/consumption-widget.js`;
        case 'widget-exchanges-switch':
            //MOCKED URL
            return `https://sr-b2c-${appEnv}-endpoint-01.azureedge.net/widget/exchanges/widget-exchanges.js`;
        default:
            return null;
    }
}


function useRequestScript(scriptTypes: Array<Scripts>) {
    useEffect(() => {
        scriptTypes.forEach((st) => {
            const dom = document.querySelector(`#${st}`);
            // add version to force get a fresh file after each deploy
            const url = `${getURl(st)}?v=${process.env.REACT_APP_VERSION}`; 
            if (!dom && url) {
                // add script tag at lazy way
                let link = document.createElement('script');
                link.id = st;
                link.src = url;
                document.body.append(link);
            }
        });
    }, [scriptTypes]);
}

export default useRequestScript;
