import { FC, ReactElement, useEffect, useState } from 'react';
import { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//Design-sys
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import { ThemeType, Brands } from 'saur-design-sys-web/lib/BrandsConfig';
import { device, onlyForMobile } from 'saur-design-sys-web/lib/Theme';
import Button from 'saur-design-sys-web/lib/Atoms/Input/Button/Button';
import AppLogo from 'saur-design-sys-web/lib/Atoms/AppLogo/AppLogo';

//CPOsm
import {
    isFeatureEnabled,
    getLanguage
} from 'saur-viseau-state-management/lib/settings/selectors';
import { States } from 'saur-viseau-state-management/lib';
import PartnerContract from 'saur-viseau-state-management/lib/contracts/models/PartnerContract';
import { contractsActions } from 'saur-viseau-state-management/lib/contracts/state';
import { States as ContractStates } from 'saur-viseau-state-management/lib';

//modules
import BurgerMenuIcon from '../components/Icons/BurgerMenuIcon';
import { DesktopMenuState } from '../common/customTypes';
import useEventListener from '../common/useEventListener';
import { RouteTypes, usePath } from '../common/navigation';
import AppFooter from './AppFooter';
import useInitialDataLoader from '../hooks/useInitialDataLoader';
import { interventionsActions } from 'saur-viseau-state-management/lib/interventions/state';

//LOCAL
import {DisplayAppMenu, LoadingContent, EmbedNotifications} from "./ContentLoggedComponnent"

type ContentLoggedWrapperProps = {
    children: ReactElement;
    displayHeader: boolean;
    displayAppMenu?: boolean;
    displayFooter?: boolean;
};

type modalMode = 'in' | 'out' | undefined;
type ContractEventType = {
    contractList: PartnerContract[];
};

const ContentLoggedWrapper: FC<ContentLoggedWrapperProps> = ({
    children,
    displayHeader,
    displayAppMenu = true,
    displayFooter = true
}) => {
    const [visibleMobileMenu, setVisibleMobileMenu] = useState<modalMode>(
        undefined
    );
    const [desktopMenuStateVar, setDesktopMenuStyle] = useState<
    DesktopMenuState
    >('full');

    const history = useHistory();
    const { getPath } = usePath();
    const dispatch = useDispatch();
    const lang = useSelector(getLanguage) as string;
    const accessToken = JSON.parse(
        localStorage.getItem('sso-response') ?? 'null'
    )?.access_token;
    const theme = useSelector((s: States) => s.settings.theme) as Brands;

    const settingState = useSelector((state: States) => state.settings);
    const logoName = settingState?.params?.LogoName || 'saur'
    const isAlertsFeaterEnabled = useSelector((state: States) =>
        isFeatureEnabled(state, 'Get-Alerts')
    );

    const contractState = useSelector(
        (state: ContractStates) => state.contracts
    );

    const { loading, loadSucceeded } = useInitialDataLoader();

    // set widgets references (Could be refactored to a custom hook)
    useEffect(() => {
        dispatch(contractsActions.setSelectedContractList([]));
    }, [dispatch, displayHeader]);

    //Action Events
    useEventListener('go-to-notifications-preferences', (e) => {
        goToProfile();
    });

    useEventListener('filter-widget_contract-refresh', (e) => {
        const contractEvent = e as CustomEvent<ContractEventType>;
        const selectedContracts = contractEvent.detail?.contractList.filter(
            (contract: PartnerContract) => contract?.communes.length > 0
        );

        console.log("filter-widget_contract-refresh" , selectedContracts)
        dispatch(contractsActions.setSelectedContractList(selectedContracts));
    });

    /// On fait cet event temporairement pour afficher quelque chose lors du chargement de la page
    useEventListener('filter-widget_contract-list', (e) => {
        const contractEvent = e as CustomEvent<ContractEventType>;
        const selectedContracts = contractEvent.detail.contractList.filter(
            (contract: any) => contract?.communes.length > 0
        );
        console.log("filter-widget_contract-list" , selectedContracts)
        if (
            contractState.selectedContractList.length === 0 &&
            selectedContracts.length > 0
        ) {
            dispatch(
                contractsActions.setSelectedContractList(selectedContracts)
            );
        }
    });

    useEventListener('inter-show_urgent-changed', (e) => {
        const event = e as CustomEvent<boolean>;
        
        dispatch(interventionsActions.setFocusUrgent(event.detail));
    });

    function goToProfile() {
        history.push({
            pathname: `/${lang}${getPath(RouteTypes.PROFILE)}`,
            search: '?section=notification-preference'
        });
    }

    function collapseMenu(style: DesktopMenuState) {
        setDesktopMenuStyle(style);
    }
    function toggleMenu(option: modalMode) {
        setVisibleMobileMenu(option);
    }

    const showContent = !loading && loadSucceeded;

    const desktopMenuStyleClassName =
        desktopMenuStateVar === 'collapse' ? 'menu-collapsed' : 'menu-full';

    const contentVisibility =
        visibleMobileMenu == 'in' || showContent
            ? 'content-is-visible'
            : 'content-is-hidden';

    const contentClassName = `right-panel ${contentVisibility} ${desktopMenuStyleClassName}`;

    return (
        <Container
            id='logged-content-wrapper'
            className={`content-logged-wrapper`}
        >
            <Container className='desktop-wrapper'>
                <DisplayAppMenu {...{displayAppMenu, toggleMenu, collapseMenu, visibleMobileMenu, desktopMenuStateVar}} />

                <Container className={contentClassName}>
                    {visibleMobileMenu !== 'out' && displayHeader && (
                        <>
                            <Container className='right-panel-header'>
                                <Container className='mobile-header'>
                                    <Container className='burger-menu-wrapper'>
                                        <Button
                                            automatedTestId='burger-menu-button'
                                            mode='basic'
                                            className='burger-menu-button'
                                            onClick={() => {
                                                toggleMenu(
                                                    visibleMobileMenu === 'in'
                                                        ? 'out'
                                                        : 'in'
                                                );
                                            }}
                                        >
                                            <BurgerMenuIcon />
                                        </Button>
                                    </Container>

                                    <Container className='logo-wrapper'>
                                        <AppLogo
                                            forceBrand={logoName}
                                            version={2} 
                                            type='normal'
                                            content='header'
                                            className={`app-logo `}
                                        />
                                    </Container>

                                    <Container
                                        className='notification-widget-wrapper-mobile'
                                        automatedTestId='notifications-widget-wrapper'
                                    >
                                        <EmbedNotifications {...{isAlertsFeaterEnabled, lang, theme, accessToken, device: 'mobile'}} />
                                    </Container>
                                </Container>
                                <Container className='mobile-filter-widget-wrapper'>
                                    <filter-widget
                                        lang={lang}
                                        theme={theme}
                                        runtime='full'
                                        token={accessToken}
                                    ></filter-widget>
                                </Container>
                                <Container className='notification-widget-wrapper-desktop'>
                                    <EmbedNotifications {...{isAlertsFeaterEnabled, lang, theme, accessToken, device: 'desktop'}} />
                                </Container>
                            </Container>
                        </>
                    )}

                    {showContent && <Container>{children}</Container>}
                </Container>

                <LoadingContent showContent={showContent} />
            </Container>
            {displayFooter && <AppFooter />}
        </Container>
    );
};

export const ContentLoggedWrapperStyles = css`
    .content-logged-wrapper {
        .desktop-wrapper {
            background-color: ${(props: ThemeType) =>
                props.theme.menuBackground};
            display: flex;
            @media ${device.mobile} {
                flex-direction: column;
            }
            @media ${device.desktop} {
                flex-direction: row;
            }
            @media ${device.tablet} {
                flex-direction: row;
            }

            .mobile-header {
                ${onlyForMobile}
                flex-direction: row;
                background-color: #f5f8fc;
            }

            .burger-menu-wrapper {
                flex: 1;
                background-color: #ffffff;
                align-items: center;
                border-radius: 8px;
            }

            .burger-menu-button {
                padding: 22px;
            }

            .logo-wrapper {
                flex: 3;
                margin-left: 10px;
                margin-right: 10px;
                align-items: center;
                justify-content: center;
            }
            .notification-widget-wrapper-mobile {
                flex: 1;
                align-items: center;
                background-color: #ffffff;
            }
            .MobileMenu {
                background-color: ${(props: ThemeType) =>
                    props.theme.menuBackground};
            }
        }

        .left-panel {
            flex: 1;
            background-color: ${(props: ThemeType) =>
                props.theme.menuBackground};
            transition: 1500ms;
        }

        .right-panel {
            flex: 3;
            background-color: #f5f8fc;

            .right-panel-header {
                @media ${device.mobile} {
                    flex-direction: column;
                    gap: 1em;
                }
                @media ${device.tablet} {
                    flex-direction: row;
                    gap: 0px;
                }
                .mobile-filter-widget-wrapper {
                    background-color: #f5f8fc;
                    z-index: 1;
                    @media ${device.tablet} {
                        flex: 1;
                        margin-right: 10px;
                    }
                }
                .mobile-filter-widget-wrapper .navigation-filter-dropdowns {
                    z-index: 1;
                }
                .notification-widget-wrapper-desktop {
                    @media ${device.mobile} {
                        display: none;
                    }
                    @media ${device.tablet} {
                        display: flex;
                    }
                }

                .collapsed {
                    visibility: collapse;
                }
            }

            @media ${device.tablet} {
                padding: 3% 6%;
                border-top-right-radius: 0px;
                border-top-left-radius: 60px;
            }
            @media ${device.mobile} {
                padding: 2% 4%;
            }

            &.content-is-visible {
                display: flex;
            }
            &.content-is-loading {
                display: flex;
                background-color: white;
                // border-top-left-radius: 0;
            }

            &.content-is-hidden {
                display: none;
            }
            &.menu-collapsed {
                flex: 12;
                border-top-left-radius: 0px;
            }
        }
    }
`;

export default ContentLoggedWrapper;
